export default {
  messages: {
    uk: {
      'Ваше авто буде видалено протягом доби': 'Ми знайшли розбіжності у даних про авто',
      'Схоже, VIN-код не відповідає авто.': 'Можливо, виникла помилка, але VIN-код не відповідає авто.<br/>Щоб розміщення не припинялось – завантажте фото техпаспорту з чітким зображенням VIN-коду',
      'Потрібна допомога?': 'Потрібна допомога?',
      'Видалення через': 'Видалення через',
      год: 'год',
      хв: 'хв',
      'Ще авто': 'Ще {count} авто',
      Згорнути: 'Згорнути',
      'Завантажити техпаспорт': 'Завантажити техпаспорт',
      'Не вдалося завантажити, спробуйте ще раз': 'Не вдалося завантажити, спробуйте ще раз',
      'Максимальний розмір фото': 'Максимальний розмір фото — 10 Мб. Спробуйте ще раз',
      'Максимальна кількість фото': 'Максимальна кількість фото — 3 файла. Спробуйте ще раз',
      'Триває перевірка': 'Триває перевірка',
      'Дякуємо!': 'Дякуємо, фото техпаспорту вже на модерації',
      'Фото техпаспорту відправлено на модерацію': 'Ваше авто, як і раніше, доступне для покупців. Як тільки ми все перевіримо – ви отримаєте сповіщення',
    },
    ru: {
      'Ваше авто буде видалено протягом доби': 'Мы нашли расхождение в данных об авто',
      'Схоже, VIN-код не відповідає авто.': 'Возможно, возникла ошибка, но VIN-код не соответствует автомобилю.<br/>Чтобы размещение не прекращалось – загрузите фото техпаспорта с четким изображением VIN-кода',
      'Потрібна допомога?': 'Нужна помощь?',
      'Видалення через': 'Удаление через',
      год: 'ч',
      хв: 'мин',
      'Ще авто': 'Еше {count} авто',
      Згорнути: 'Свернуть',
      'Завантажити техпаспорт': 'Загрузить техпаспорт',
      'Не вдалося завантажити, спробуйте ще раз': 'Не удалось загрузить, попробуйте еще раз',
      'Максимальний розмір фото': 'Максимальный размер фото - 10 Мб. Попробуйте еще раз',
      'Максимальна кількість фото': 'Максимальное количество фотографий – 3 файла. Попробуйте еще раз',
      'Триває перевірка': 'Идет переверка',
      'Дякуємо!': 'Спасибо, фото техпаспорта уже на модерации',
      'Фото техпаспорту відправлено на модерацію': 'Ваше авто по-прежнему доступно для покупателей. Как только мы все проверим – вы получите уведомление',
    },
  },
};
