import { mapActions } from 'vuex';
import i18n from './i18n';

export default {
  data() {
    return {
      uploading: false,
      errorText: '',
      uploaded: false,
    };
  },
  props: {
    id: Number,
    uploadDocsForModeration: Boolean,
  },
  methods: {
    ...mapActions({
      uploadPostModerationDocs: 'cabinet/advertisements/manage/uploadPostModerationDocs',
      uploadAdvertisementDocs: 'cabinet/advertisements/manage/uploadAdvertisementDocs',
      getAdvertisementDocs: 'cabinet/advertisements/manage/getAdvertisementDocs',
    }),
    checkErrorStatus(status) {
      if (this.errorText) return;

      switch (status) {
        case 200:
        case 201:
          this.errorText = '';
          break;
        case 413:
          this.errorText = 'Файл занадто великий';
          break;
        default:
          this.errorText = 'Помилка завантаження';
      }
    },
    async uploadDocuments(event) {
      const { target: { files = [] } = {} } = event;
      const requestMethod = this.uploadDocsForModeration ? 'uploadPostModerationDocs' : 'uploadAdvertisementDocs';

      this.errorText = '';

      if (!files.length) return;

      this.uploading = true;

      await this[requestMethod]({ files, autoId: String(this.id) })
        .then((response) => {
          if (response.statuses) {
            response.statuses?.forEach((file) => this.checkErrorStatus(file?.status));
          } else {
            this.checkErrorStatus(response?.status);
          }

          if (!this.errorText) this.uploaded = true;
        }).catch(() => {
          this.checkErrorStatus(400);
        });

      this.uploading = false;
    },
  },
  computed: {
    btnTitle() {
      return this.uploading ? 'Завантаження...' : 'Завантажити документи';
    },
  },
  async mounted() {
    const docs = await this.getAdvertisementDocs({ autoId: this.id });
    this.uploaded = Boolean(docs?.length);
  },
  i18n,
};
