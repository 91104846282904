export default {
  messages: {
    uk: {
      'Завантажити документи': 'Завантажити документи',
      'Документи завантажено': 'Документи завантажено',
      'Завантаження...': 'Завантаження...',
      'Помилка завантаження': 'Помилка завантаження',
      'Файл занадто великий': 'Файл занадто великий',
    },
    ru: {
      'Завантажити документи': 'Загрузить документы',
      'Документи завантажено': 'Документы загружено',
      'Завантаження...': 'Загрузка...',
      'Помилка завантаження': 'Ошибка загрузки',
      'Файл занадто великий': 'Файл слишком велик',
    },
  },
};
