module.exports = {
  data() {
    return {
      networks: {
        fb: function (purl) {
          let url = 'http://www.facebook.com/sharer.php?';
          url += 'p[url]=' + encodeURIComponent(purl + '?utm_source=facebook&utm_medium=share_cabinet&utm_campaign=ads_bu&r_audience=smo&r_source=facebook&r_medium=share_cabinet&r_campaign=ads_bu');
          url += '&u=' + encodeURIComponent(purl + '?utm_source=facebook&utm_medium=share_cabinet&utm_campaign=ads_bu&r_audience=smo&r_source=facebook&r_medium=share_cabinet&r_campaign=ads_bu');
          url += '&s=100';
          return url;
        },
        tg: function (purl) {
          let url = 'https://t.me/share/url?';
          url += 'url=' + encodeURIComponent(purl + '?utm_source=telegram&utm_medium=share_cabinet&utm_campaign=ads_bu&r_audience=smo&r_source=telegram&r_medium=share_cabinet&r_campaign=ads_bu');
          return url;
        },
        vb: function (purl) {
          let url = 'viber://forward?';
          url += 'text=' + encodeURIComponent(purl + '?utm_source=viber&utm_medium=share_cabinet&utm_campaign=ads_bu&r_audience=smo&r_source=viber&r_medium=share_cabinet&r_campaign=ads_bu');
          return url;
        }
      }
    }
  },
  methods : {
    share: function (network, uri) {
      return window.open(this.networks[network](`https://auto.ria.com${uri}`), '', 'toolbar=0,status=0,width=600,height=436');
    }
  }
};
