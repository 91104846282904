export default {
	messages: {
		ru: {
			'Налаштування перепублікації': 'Настройка перепубликации',
			'Автоматична перепублікація': 'Автоматическая перепубликация',
			'вимкнена': 'выключена',
			'увімкнена': 'включена',
			'Видалити торги, обміни, коментарі': 'Удалить торги, обмены, комментарии',
			'Обнулити кількість переглядів': 'Обнулить количество просмотров',
			'Увімкнути додаткові налаштування': 'Включить дополнительные настройки',
			'Виберіть частоту перепублікації': 'Выберите частоту перепубликации',
			'Виберіть час перепублікації': 'Выберите время перепубликации',
			'Зберегти': 'Сохранить',
		},
		uk: {
			'Налаштування перепублікації': 'Налаштування перепублікації',
			'Автоматична перепублікація': 'Автоматична перепублікація',
			'вимкнена': 'вимкнена',
			'увімкнена': 'увімкнена',
			'Видалити торги, обміни, коментарі': 'Видалити торги, обміни, коментарі',
			'Обнулити кількість переглядів': 'Обнулити кількість переглядів',
			'Увімкнути додаткові налаштування': 'Увімкнути додаткові налаштування',
			'Виберіть частоту перепублікації': 'Виберіть частоту перепублікації',
			'Виберіть час перепублікації': 'Виберіть час перепублікації',
			'Зберегти': 'Зберегти'
		}
	}
};
