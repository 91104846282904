import { mapActions, mapGetters } from 'vuex';
import qrCodeText from './qrCodeText.json';

export default {
  name: 'QRCode',

  data() {
    return {
      qrCodeText,
    };
  },
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
  },

  computed: {
    ...mapGetters({
      popup: 'cabinet/advertisements/popups/popup',
      advertisementInfo: 'cabinet/advertisements/advertisement/info',
    }),
    ...mapGetters('Common/qrCode', {
      getSvg: 'getSvg',
      getLoading: 'getLoading',
    }),
    advertisementId() {
      return this.popup.id || 0;
    },
    currentAdvertisement() {
      return this.advertisementInfo(this.advertisementId) || {};
    },
    currentAdvertisementUrl() {
      const url = 'https://auto.ria.com/uk';
      return `${url}${this.currentAdvertisement?.uri}`;
    },
    isCurrentPlateNumber() {
      return Boolean(this.currentAdvertisement?.autotest?.plateNumberData?.value);
    },
    getQRCodePDFUrl() {
      const baseApiUrl = 'https://auto.ria.com/qr_code/get_qr/';
      const params = {
        url: this.currentAdvertisementUrl,
        withPlateNumber: this.isCurrentPlateNumber,
        format: 'pdf',
      };
      const searchParamsStr = new URLSearchParams(params);
      return `${baseApiUrl}?${searchParamsStr}`;
    },
  },
  methods: {
    ...mapActions({
      closePopup: 'cabinet/advertisements/popups/close',
    }),
    ...mapActions('Common/qrCode', {
      setQRCodeSvg: 'setQRCodeSvg',
    }),
    async init() {
      await this.setQRCodeSvg({
        url: this.currentAdvertisementUrl,
        withPlateNumber: this.isCurrentPlateNumber,
        format: 'svg',
      });
    },
  },
  mounted() {
    this.init();
  },
  i18n: require('./i18n').default,
};
