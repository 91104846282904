export default {
  messages: {
    uk: {
      Публикация: 'Публікація',
      Переопубликовать: 'Переопублікувати',
      'Опубликовать повторно': 'Опублікувати повторно',
      Редактировать: 'Редагувати',
      Поднять: 'Підняти',
      'Удалить с сайта': 'Видалити з сайту',
      'Обновление даты и продление срока публикации объявления': 'Оновлення дати та продовження терміну публікації оголошення',
      'Поднять в поиске': 'Підняти в пошуку',
      'Перейти к редактированию объявления': 'Перейти до редагування оголошення',
      'Сообщить о продаже': 'Повідомити про продаж',
      'Удалить в архив': 'Видалити в архів',
      'Публикация объявления': 'Публікація оголошення',
      'Оплатить ранее созданный заказ': 'Оплатити раніше створений замовлення',
      Оплатить: 'Оплатити',
      Восстановить: 'Відновити',
      Опубликовать: 'Опублікувати',
      'Действия с объявлением': 'Дії з оголошенням',
      Отмена: 'Скасування',
      Создано: 'Створене',
      'Указать VIN-код': 'Вказати VIN-код',
      день: '0 днів | {count} день | {count} дні | {count} днів',
      часов: '0 годин | {count} година | {count} години | {count} годин',
      минут: '0 хвилин | {count} хвилина | {count} хвилини | {count} хвилин',
      Проверить: 'Перевірити',
      Перепубликация: 'Переопублікація',
      выключена: 'вимкнена',
      включена: 'увімкнена',
      еще: 'ще',
      до: 'до',
      'Управління телефонами': 'Управління телефонами',
      'Изменить ставку': 'Змінити ставку',
      'Настроить перепубликацию': 'Налаштувати перепублікацію',
      'Змінити телефон': 'Змінити телефон',
      'Підніміть у ТОП пошуку': 'Підніміть у ТОП пошуку',
      знижка: 'знижка',
      'Завантажити QR-код': 'Завантажити QR-код',
      'Ваша пропозиція в QR-коді': 'Ваша пропозиція в QR-коді',
      'Роздрукуйте QR-код': 'Роздрукуйте QR-код',
      'Наклейте на авто': 'Наклейте на авто',
      'Запропонуйте просканувати його покупцеві': 'Запропонуйте просканувати його покупцеві',
      'І про ваше авто знатимуть все — достатньо навести камеру смартфона!': 'І про ваше авто знатимуть все — достатньо навести камеру смартфона!',
    },
    ru: {
      Публикация: 'Публикация',
      Переопубликовать: 'Переопубликовать',
      'Опубликовать повторно': 'Опубликовать повторно',
      Редактировать: 'Редактировать',
      Поднять: 'Поднять',
      'Удалить с сайта': 'Удалить с сайта',
      'Обновление даты и продление срока публикации объявления': 'Обновление даты и продление срока публикации объявления',
      'Поднять в поиске': 'Поднять в поиске',
      'Перейти к редактированию объявления': 'Перейти к редактированию объявления',
      'Сообщить о продаже': 'Сообщить о продаже',
      'Удалить в архив': 'Удалить в архив',
      'Публикация объявления': 'Публикация объявления',
      'Оплатить ранее созданный заказ': 'Оплатить ранее созданный заказ',
      Оплатить: 'Оплатить',
      Восстановить: 'Восстановить',
      Опубликовать: 'Опубликовать',
      'Действия с объявлением': 'Действия с объявлением',
      Отмена: 'Отмена',
      Создано: 'Создано',
      'Указать VIN-код': 'Указать VIN-код',
      день: '0 дней | {count} день | {count} дня | {count} дней',
      часов: '0 часов | {count} час | {count} часа | {count} часов',
      минут: '0 минут | {count} минута | {count} минуты | {count} минут',
      Проверить: 'Проверить',
      Перепубликация: 'Перепубликация',
      выключена: 'выключена',
      включена: 'включена',
      еще: 'еще',
      до: 'до',
      'Управління телефонами': 'Управление телефонами',
      'Изменить ставку': 'Изменить ставку',
      'Настроить перепубликацию': 'Настроить перепубликацию',
      'Змінити телефон': 'Изменить телефон',
      'Підніміть у ТОП пошуку': 'Поднимите в ТОП поиска',
      знижка: 'скидка',
      'Завантажити QR-код': 'Загрузить QR-код',
      'Ваша пропозиція в QR-коді': 'Ваше предложение в QR-коде',
      'Роздрукуйте QR-код': 'Распечатайте QR-код',
      'Наклейте на авто': 'Наклейте на авто',
      'Запропонуйте просканувати його покупцеві': 'Предложите просканировать его покупателю',
      'І про ваше авто знатимуть все — достатньо навести камеру смартфона!': 'И о вашем авто будут знать все – достаточно навести камеру смартфона!',
    },
  },
};
