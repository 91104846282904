import { mapActions, mapGetters } from 'vuex';

const { get } = require('../../../../../helpers/cookie');

export default {
  props: {
    id: Number,
  },
  methods: {
    ...mapActions({
      loadPosition: 'cabinet/advertisements/advertisement/position',
    }),
  },
  computed: {
    ...mapGetters({
      position_: 'cabinet/advertisements/advertisement/position',
      info_: 'cabinet/advertisements/advertisement/info',
    }),
    position() {
      return this.position_(this.id);
    },
    info() {
      return this.info_(this.id) || {};
    },
    custom() {
      // @todo і в україні
      if (Number(this.info.custom) && Number(!this.info.abroad)) {
        return '&custom.not=0&abroad.not=0&indexName=auto';
      }
      return '';
    },
    abroad() {
      // @todo для авто не в україні
      if (Number(this.info.custom) && Number(this.info.abroad)) {
        return '&abroad.not=1&custom.not=0';
      }
      return '';
    },
    page() {
      const size = (_TARGET_ === 'client' && Number(get('ipp'))) || 20;
      return `&page=${~~(this.position / size)}&size=${size}`;
    },
    searchLink() {
      if (!Object.keys(this.info).length) return '';

      const { model: { id: modelId }, brand: { id: brandId } = {}, category: { id: categoryId } = {} } = {} = this.info;
      // для нерозмитнених
      // для авто не в україні
      return `${this.langPrefix}/search/?categories.main.id=${categoryId}&brand.id[0]=${brandId}&model.id[0]=${modelId}${this.custom}${this.abroad}${this.page}&scrollToAuto=${this.id}`;
    },
  },
  mounted() {
    const { model: { id: modelId } = {}, brand: { id: brandId } = {}, category: { id: categoryId } = {} } = {} = this.info || {};
    // @todo потрібно ще передати abroad
    this.loadPosition({
      id: this.id, brandId, modelId, custom: this.info.custom, abroad: this.abroad, categoryId,
    });
  },
  i18n: {
    messages: {
      ru: {
        'Ваше предложение на': 'Ваше предложение на',
        'позиции в поиске': 'позиции в поиске',
        'Смотреть в поиске': 'Смотреть в поиске',
      },
      uk: {
        'Ваше предложение на': 'Ваша пропозиція на',
        'позиции в поиске': 'позиції в пошуку',
        'Смотреть в поиске': 'Дивитися в пошуку',
      },
    },
  },
};
