import { mapActions } from 'vuex';
import i18n from '../i18n';

export default {
  name: 'FileLoaderWithTimer',
  props: {
    page: {
      type: String,
      default: 'published-adv',
      validator(value) {
        return ['published-adv', 'cabinet-main'].includes(value);
      },
    },
    item: Object,
    slonikParams: Object,
  },
  data() {
    return {
      loadingDocs: false,
      errorText: false,
      successLoadedDocs: false,
      timer: null,
      computedTimerValue: '',
    };
  },
  computed: {
    moderation() {
      return this.item?.postmoderation || {};
    },
    moderationComputedFields() {
      return {
        color: this.successLoadedDocs ? 'green' : this.item?.postmoderation?.color,
        title: this.successLoadedDocs ? this.$t('Дякуємо!') : this.item?.postmoderation?.title,
        description: this.successLoadedDocs ? this.$t('Фото техпаспорту відправлено на модерацію') : this.item?.postmoderation?.description,
      } || {};
    },
    hideBlockTitle() {
      return this.page === 'cabinet-main' && this.moderationComputedFields.color === 'red';
    },
  },
  methods: {
    ...mapActions({
      uploadPostModerationDocs: 'cabinet/advertisements/manage/uploadPostModerationDocs',
      updateLocalPostModeration: 'cabinet/advertisements/advertisement/updateLocalPostModeration',
    }),
    checkErrorStatus(status) {
      if (this.errorText) return;

      switch (status) {
        case 200:
        case 201:
          this.errorText = '';
          this.updateLocalPostModeration({
            id: this.item.id,
            postmoderation: {
              color: 'green',
              title: this.$t('Дякуємо!'),
              buttonShow: true,
              description: this.$t('Фото техпаспорту відправлено на модерацію'),
            },
          });
          this.logAnalytic({ request_type: 2 });
          break;
        case 413:
          this.errorText = 'Максимальний розмір фото';
          this.logAnalytic({ request_type: 3 });
          break;
        default:
          this.errorText = 'Не вдалося завантажити, спробуйте ще раз';
          this.logAnalytic({ request_type: 3 });
      }
    },
    async uploadDocuments(event) {
      const { target: { files = [] } = {} } = event;

      if (files.length > 3) {
        this.errorText = 'Максимальна кількість фото';
        return;
      }

      this.loadingDocs = true;
      this.errorText = '';

      await this.uploadPostModerationDocs({ files, autoId: String(this.item.id) })
        .then((response) => {
          if (response.statuses) {
            response.statuses?.forEach((file) => this.checkErrorStatus(file?.status));
          } else {
            this.checkErrorStatus(response?.status);
          }

          if (!this.errorText) this.successLoadedDocs = true;
        }).catch(() => {
          this.checkErrorStatus(400);
        });

      this.loadingDocs = false;
    },
    createTimer(endTimeString, intervalSec) {
      const endTime = new Date(endTimeString);

      const formatTime = (remainingSeconds) => {
        const formattedHours = Math.floor(remainingSeconds / 3600);
        const formattedMinutes = Math.floor((remainingSeconds % 3600) / 60);
        const displayHours = String(formattedHours).padStart(2);
        const displayMinutes = String(formattedMinutes).padStart(2);

        return `${displayHours} ${this.$t('год')} ${displayMinutes} ${this.$t('хв')}`;
      };

      const stop = () => {
        // eslint-disable-next-line no-use-before-define
        clearInterval(Number(intervalId));
      };

      const updateTimer = () => {
        const remainingSeconds = Math.max(0, Math.floor((endTime - new Date()) / 1000));

        this.computedTimerValue = formatTime(remainingSeconds);

        if (remainingSeconds === 0) {
          stop();
        }
      };

      const intervalId = setInterval(updateTimer, 1000 * (intervalSec || 1));
      updateTimer();

      return {
        stop,
      };
    },
    logAnalytic(params) {
      this._slonik({
        ...this.slonikParams || {},
        ...params,
      });
    },
  },
  watch: {
    item: {
      handler(value) {
        if (value?.postmoderation?.dateEnd) {
          if (this.item?.postmoderation?.color === 'red') this.logAnalytic({ request_type: 1 });
          this.timer = this.createTimer(value?.postmoderation?.dateEnd, 60);
        }
      },
      immediate: true,
    },
  },
  i18n,
};
