import { mapActions, mapGetters } from 'vuex';

export default {
	name: 'RepublicationPopup',
	components: {
		Popup: () => import('../../../Common/Popup.vue'),
	},
	data() {
		return {
			active: 0,
			clearViewCount: 0,
			clearWall: 0,
			time: '08:00',
			useAdvancedSettings: 0,
			type: 1,
			isEnableAdvancedSettings: 0,
		};
	},
	computed: {
		...mapGetters({
			popup: 'cabinet/advertisements/popups/popup',
			getGeneralRepublicationInfo_: 'cabinet/advertisements/manage/getGeneralRepublicationInfo',
			getRepublicationInfoById_: 'cabinet/advertisements/manage/getRepublicationInfoById',
		}),
		id() {
			return this.popup.id || 0;
		},
		ids() {
			return this.popup.ids || [];
		},
		info() {
			// eslint-disable-next-line max-len
			return this.id ? this.getRepublicationInfoById_(this.id) : this.getGeneralRepublicationInfo_ || {};
		},
		reverseActive() {
			const isActive = Boolean(this.popup.active !== undefined);
			const value = Number(this.popup.active) ? 1 : 0;
			return {isActive, value};
		},
		massRepublicationsText() {
			let msg = '';
			if (this.ids.length) {
				msg = this._result(this.info, 'messages.head', '').replace(/%adsCount/, this._result(this.info, 'messages.args.adsCount', ''));
			}
			return msg;
		},
		republicationText() {
			let msg = '';
			const texts = this._result(this.info, 'messages.title', {});
			const daysToRepublication = this._result(this.info, 'messages.args.daysToRepublication', '');
			const daysToDraft = this._result(this.info, 'messages.args.daysToDraft', '');
			if (this.id) {
				const activeOption = this._result(this.info, 'typeOptions', []).find(({val = 1}) => val === this.type);
				const activeOptionText = this._result(activeOption, 'daysToRepublication', '');
				msg = this.active ?
					this._result(texts, 'enable', '').replace(/%daysToRepublication/, this.useAdvancedSettings ? this.checkRepublicationDays(daysToDraft, activeOptionText) : daysToDraft)
					:
					this._result(texts, 'disable', '').replace(/%daysToDraft/, this._result(this.info, 'messages.args.daysToDraft', ''));
			} else {
				const isActive = Boolean(Number(this.active));
				const isUseAdvancedSettings = Boolean(Number(this.useAdvancedSettings));
				switch (true) {
					case isActive && isUseAdvancedSettings:
						msg = texts.enableWithSettings;
						break;
					case isActive:
						msg = texts.enable;
						break;
					case !isActive:
						msg = texts.disable;
						break;
				}
			}
			return msg;
		},
		generalTextForAll() {
			return !this.id && !this.ids.length ? this._result(this.info, 'messages.head', '') : '';
		},
		stopSubmit() {
			const watchList = ['active', 'clearViewCount', 'clearWall', 'time', 'useAdvancedSettings', 'type'];
			const isMassRepublication = Boolean(this.ids.length);
			// eslint-disable-next-line max-len
			return isMassRepublication ? false : watchList.every(key => this[key] === this.info[key]);
		},
		isActiveAdditionalSettings() {
			return !Number(this.active) || !Number(this.useAdvancedSettings);
		},
	},
	methods: {
		...mapActions({
			close_: 'cabinet/advertisements/popups/close',
			fetchRepublicationInfo_: 'cabinet/advertisements/manage/getRepublicationInfo',
			updateRepublicationInfo_: 'cabinet/advertisements/manage/updateRepublicationInfo',
		}),
		getRepublicationInfo() {
			const params = {
				id: this.id
			};
			if (this.ids.length) {
				Object.assign(params, {adsCount: this.ids.length});
			}
			this.fetchRepublicationInfo_(params);
		},
		updateRepublicationInfo() {
			const config = {
				active: this.active,
				clearViewCount: this.clearViewCount,
				clearWall: this.clearWall,
				time: this.time,
				useAdvancedSettings: this.useAdvancedSettings,
				type: this.type,
			};
			if (this.id) {
				Object.assign(config, {autoIds: [this.id]});
			}
			if (this.ids.length) {
				Object.assign(config, {autoIds: [...this.ids]});
			}
			this.updateRepublicationInfo_(config);
			this.close_();
		},
		checkRepublicationDays(staticDays = '', dynamicDays = '') {
			return parseInt(dynamicDays) < parseInt(staticDays) ? dynamicDays : staticDays;
		},
	},
	i18n: require('./i18n').default,
	watch: {
		info(newValue) {
			this.active = this.reverseActive.isActive ? this.reverseActive.value : newValue.active;
			this.clearViewCount = newValue.clearViewCount;
			this.clearWall = newValue.clearWall;
			this.time = newValue.time;
			this.useAdvancedSettings = newValue.useAdvancedSettings;
			this.type = newValue.type;
			this.isEnableAdvancedSettings = newValue.isEnableAdvancedSettings;
		},
	},
	mounted() {
		this.getRepublicationInfo(this.id);
	}
};
