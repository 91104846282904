import {mapActions, mapGetters} from 'vuex';
const {set, get} = require('../../../../helpers/cookie');
const PERIOD = 60 * 60 * 24 * 30;

module.exports = {
	data: function () {
		return {
			isShowAlert: true,
			routeName: this.$route.name,
			closedAlerts: [],
			priorityList: {
				blockedPhone: 1,
				emptyPhone: 2,
				notConfirmedPhone: 3,
				chatDisabled: 4,
				emptyUsername: 5,
				emptyVinCodeMain: 6,
				emptyVinCodeAdv: 7
			},
			filteredList: [],
		};
	},
	name: 'Alerts',
	mounted() {
		this.setAlerts();
	},
	methods: {
		...mapActions({
			setAlerts: 'cabinet/alerts/alerts'
		}),
		removeAlert(blockName) {
			set(`alert_${blockName}`, 1, { expires: PERIOD });
			this.closedAlerts.push(blockName);
		},
		showAlert(blockName) {
			return Boolean(_TARGET_ === 'client' && !Number(get(`alert_${blockName}`)));
		},
		sendAlert(blockName, id) {
			this.gaEvent(`Cabinet_${this.routeName}`, `click_on_${blockName}_${id}`, 'promo_block');
			this.removeAlert(blockName);
		},
		showFirstAlert(results) {
			const alerts = results.length ? results : this.alerts;
			if (this.routeName === 'CabinetMain') {
				const withoutCookie = alerts.filter(({name = ''}) => !get(`alert_${name}`));
				const sortedAlerts = withoutCookie.map(el => {
					el.sort = this.priorityList[el.name] || 0;
					return el;
				}).sort((a, b) => a.sort - b.sort);
				const firstAlert = sortedAlerts[0];
				if (firstAlert) {
					this.filteredList.push(firstAlert);
				}
			}
		},
	},
	computed: {
		...mapGetters({
			alerts_: 'cabinet/alerts/alerts'
		}),
		alerts() {
			if (this.alerts_.length) {
				this.showFirstAlert(this.alerts_);
			}
			// eslint-disable-next-line max-len
			return this.filteredList.length ? this.filteredList : this.alerts_.length ? this.alerts_ : [];
		}
	}
};
