import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import getBase64fromFile from '../../../../helpers/getBase64fromFile';
import { get } from '../../../../helpers/cookie';

export default {
  name: 'Advertisement',
  data() {
    return {
      files: [],
      excuse: '',
      excuseSent: false,
      onScreen: false,
      advertIdWithUploadDocs: [],
      usersIdCanUploadDocs: [
        12372298, 12986106, 10742944, 13148979, 12475771, 12456593, 12907308, 13167128, 11854668,
        12093601, 12090399, 5099270, 13204562, 11554537, 12408321, 13007269, 10826059, 13564013,
        9763509, 375470, 7470210, 5380927, 11513948, 11302935, 9763509, 7341619, 8336392, 344807,
        361774, 12972223, 9717714, 7487409, 4961996, 12964367, 949930, 11687367, 13582827, 10467106,
        10886254, 14213414, 2049564, 14129552, 13205286, 13376442, 7935212, 13399813, 7099561,
        12365384, 5814186, 11815325, 9612976, 7350048, 11262657, 12560226, 8562371, 14224478,
        1202646, 14597261, 14586412,
      ],
    };
  },
  mounted() {
    this.reloadLazyData();
  },
  props: {
    id: Number,
    checked: Boolean,
    mainPageComponent: Boolean,
    reloadLazy: Boolean,
  },
  model: {
    prop: 'checked',
    event: 'change',
    photoProof: [],
  },
  methods: {
    ...mapActions({
      openPopup: 'cabinet/advertisements/popups/open',
    }),
    reloadLazyData() {
      const _this = this;
      try {
        const IOW = require('../../../../helpers/IntersectionObserver_screen__01');
        IOW.add(this.$el, (entry = {}) => {
          _this.onScreen = Boolean(entry.isIntersecting);
          if (_this.onScreen) {
            IOW.del(entry.target);
            this.$emit('checkLazy', _this.id);
          }
        });
      } catch (error) {
        _this.onScreen = true;
        console.error(error);
      }
    },
    async onFileSelected(event) {
      Array.from(event.target.files).forEach((item, index) => {
        this.files.push(undefined);
        getBase64fromFile(item).then((image) => {
          this.uploadPhoto(image).then((response) => {
            if (response.data.image) {
              this.files.pop();
              this.files.unshift(response.data.image);
            }
          });
        });
      });
    },
    uploadPhoto(image) {
      return axios({
        url: '/users/moderation-upload',
        method: 'POST',
        data: {
          image,
          userId: this._auth.userId,
          advertisementId: this.info.id,
        },
        headers: { 'Content-Type': 'application/json' },
      }).catch((e) => {
        console.error('/users/moderation-upload', e.message);
      });
    },
    sendExcuse() {
      return axios({
        url: '/users/moderation-abroad',
        method: 'POST',
        data: {
          message: this.excuse ? this.excuse : undefined,
          files: this.files,
          userId: this._auth.userId,
          advertisementId: this.info.id,
        },
        headers: { 'Content-Type': 'application/json' },
      }).then((response) => {
			  if (response.status === 200) {
			    this.excuseSent = true;
        }
      }).catch((e) => {
        console.error('/users/moderation-upload', e.message);
      });
    },
  },
  components: {
    Statistic: () => import('./Statistic/index.vue'),
    Photo: () => import('./Photo/index.vue'),
    Info: () => import('./Info/index.vue'),
    Share: () => import('./Share/index.vue'),
    Actions: () => import('./Actions/index.vue'),
    AutoBuyStatus: () => import('./Autoria24/index.vue'),
    FileLoaderWithTimer: () => import('../../Main/PostModeration/FileLoaderWithTimer/index.vue'),
  },
  computed: {
    ...mapGetters({
      _auth: 'cabinet/userInfo/_auth',
      segment: 'cabinet/segment',
      info_: 'cabinet/advertisements/advertisement/info',
      type: 'cabinet/advertisements/type',
      fetchAdvertisements: 'cabinet/advertisements/advertisement/fetch',
    }),
    info() {
      return this.info_(this.id);
    },
    ticketClass() {
      return this.info ? this.info?.status?.toLowerCase() : '';
    },
    links() {
      return {
        edit: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/`,
      };
    },
    isAutoria24() {
      return this.info && ['AUTO_BUY', 'AUCTION_END', 'AUCTION_START'].includes(this.info.status);
    },
    autoDealersActiveAdv() {
      return ['ACTIVE'].includes(this.info?.status) && ['b2cAutoDealer'].includes(this.segment);
    },
    uploadDocsForModeration() {
      return this.autoDealersActiveAdv;
    },
    canUploadDocs() {
      const hasCookie = _TARGET_ === 'client' && Number(get('test_new_features')) === 8899;
      const conditionForOtherCars = this.advertIdWithUploadDocs.includes(Number(this.info?.id));
      const conditionForElectricCars = Boolean(
        this.info
        && ['DRAFT'].includes(this.info.status)
        && Number(this.info.fuel.id) === 6
        && this.info.VIN
        && (this.usersIdCanUploadDocs.includes(this._auth.userId) || hasCookie),
      );

      return conditionForElectricCars || conditionForOtherCars || this.autoDealersActiveAdv;
    },
  },
  watch: {
    checked(checked) {
      if (checked && this.info.status === 'DELETED_BY_ADMIN') {
        this.$emit('change', false);
      }
    },
    reloadLazy() {
      this.onScreen = false;
      this.reloadLazyData();
    },
  },
  i18n: {
    messages: {
      uk: {
        детали: 'деталі',
        'Ваша заявка ожидает рассмотрения': 'Ваша заявка очікує на розгляд',
        архив: 'архів',
        'Удалить в архив': 'Видалити в архів',
        'Перейти к редактированию объявления': 'Перейти до редагування оголошення',
        Редактировать: 'Редагувати',
        'Во время модерации вашему авто': 'Під час модерації вашому авто',
        'присвоен статус «Авто не в Украине». Предложение перемещено в раздел': 'присвоєно статус «Авто не в Україні». Пропозиція переміщена в розділ',
        '«Авто под заказ»': '«Авто під замовлення»',
        'и недоступно в поиске среди авто с пробегом в Украине.': 'і недоступна в пошуку серед авто з пробігом в Україні.',
        'За подробностями обращайтесь в': 'За подробицями звертайтесь до',
        'Службу заботы о пользователях': 'Служби турботи про користувачів',
        'Чтобы подтвердить, что автомобиль находится в Украине, загрузите фото (на выбор): техпаспорта, таможенной декларации, автомобиля с открытым номерным знаком или VIN-кода.': 'Щоб підтвердити, що автомобіль знаходиться в Україні, завантажте фото (на вибір): техпаспорта, митної декларації, автомобіля з відкритим номерним знаком або VIN-коду.',
        'Добавить фото': 'Додати фото',
        'Оставить комментарий': 'Залишити коментар',
        Сохранить: 'Зберегти',
        'Фото отправлено. Мы рассмотрим ваше подтверждение в ближайшее время.': 'Фото надіслано. Ми розглянемо ваше підтвердження найближчим часом.',
      },
      ru: {
        детали: 'детали',
        'Ваша заявка ожидает рассмотрения': 'Ваша заявка ожидает рассмотрения',
        архив: 'архив',
        'Удалить в архив': 'Удалить в архив',
        'Перейти к редактированию объявления': 'Перейти к редактированию объявления',
        Редактировать: 'Редактировать',
        'Во время модерации вашему авто': 'Во время модерации вашему авто',
        'присвоен статус «Авто не в Украине». Предложение перемещено в раздел': 'присвоен статус «Авто не в Украине». Предложение перемещено в раздел',
        '«Авто под заказ»': '«Авто под заказ»',
        'и недоступно в поиске среди авто с пробегом в Украине.': 'и недоступно в поиске среди авто с пробегом в Украине.',
        'За подробностями обращайтесь в': 'За подробностями обращайтесь в',
        'Службу заботы о пользователях': 'Службу заботы о пользователях',
        'Чтобы подтвердить, что автомобиль находится в Украине, загрузите фото (на выбор): техпаспорта, таможенной декларации, автомобиля с открытым номерным знаком или VIN-кода.': 'Чтобы подтвердить, что автомобиль находится в Украине, загрузите фото (на выбор): техпаспорта, таможенной декларации, автомобиля с открытым номерным знаком или VIN-кода.',
        'Добавить фото': 'Добавить фото',
        'Оставить комментарий': 'Оставить комментарий',
        Сохранить: 'Сохранить',
        'Фото отправлено. Мы рассмотрим ваше подтверждение в ближайшее время.': 'Фото отправлено. Мы рассмотрим ваше подтверждение в ближайшее время.',
      },
    },
  },
};
