import {mapActions, mapGetters} from "vuex";

export default {
	name: 'SwitchPhone',
	data() {
		return {
			phonesInfo: [],
			requestData: [],
			isAssigned: Boolean,
		};
	},
	components: {
		Popup: () => import('../../../Common/Popup.vue'),
	},

	computed: {
		...mapGetters({
			popup: 'cabinet/advertisements/popups/popup',
		}),
		advertisementId() {
			return this.popup.id || 0;
		},
		popupLogData() {
			return {
				event_id: 1032,
				action_block: 2,
				proposal_id: this.advertisementId
			};
		},
	},
	methods: {
		...mapActions({
			getAdvertisementAssignedPhones: 'cabinet/advertisements/manage/getAdvertisementAssignedPhones',
			postAdvertisementAssignedPhones: 'cabinet/advertisements/manage/postAdvertisementAssignedPhones',
			closePopup: 'cabinet/advertisements/popups/close',
		}),
		getRepublicationInfo(advertisementId) {
			const params = {
				advertisementId: advertisementId
			};
			this.getAdvertisementAssignedPhones(
				params
			).then((data) => {
				if (data) {
					this.phonesInfo = data;
				} else {
					this.closePopup();
				}
			});
		},
		changeAssigned(advertisementId, id, isAssigned){
			this.changePhoneLogger({ click_action: 2, phone_id: id, item_type: isAssigned ? 1 : 0 });
			this.requestData = this.phonesInfo.map((item)=>{
				this.isAssigned = id === item.id ? !item.isAssigned : item.isAssigned;
				return { phoneId: item.id, type: this.isAssigned ? "set" : "delete" }
			});
			let promise = new Promise((resolve) => {
				resolve();
			});
			promise.then(() => this.postAdvertisementAssignedPhones({
				advertisementId: advertisementId,
				requestData: this.requestData,
			})).then((data) => {
				this.phonesInfo = data;
			});
		},
		changePhoneLogger(logData) {
			this._slonik({ ...this.popupLogData, ...logData });
		},
	},
	mounted() {
		this.getRepublicationInfo(this.advertisementId);
	},
	i18n: {
		messages: {
			uk: {
				'Додати інший номер': 'Додати інший номер',
				'Змінити телефон': 'Змінити телефон',
				'Неможливо вимкнути всі телефони': 'Неможливо вимкнути всі телефони',
				'Обирайте який з ваших номерів телефону буде показано покупцям  в оголошенні для звʼязку з вами': 'Обирайте який з ваших номерів телефону буде показано покупцям  в оголошенні для звʼязку з вами',
			},
			ru: {
				'Додати інший номер': 'Добавить другой номер',
				'Змінити телефон': 'Изменить телефон',
				'Неможливо вимкнути всі телефони': 'Невозможно отключить все телефоны',
				'Обирайте який з ваших номерів телефону буде показано покупцям  в оголошенні для звʼязку з вами': 'Выбирайте какой из ваших номеров телефона будет показан покупателям в объявлении для связи с вами',
			}
		}
	},
};
