import { mapGetters } from "vuex";

export default {
  props: {
    id: Number
  },
  mixins: [require('../../../../../mixins/share')],
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      userInfo: "cabinet/userInfo/userData",
      langId: "lang/id",
      langPrefix: "lang/prefix",
    }),
    info() {
      return this.info_(this.id);
    }
  },
  methods: {
    share_(type) {
      let social_type = '';
      switch (type) {
        case 'fb':
          social_type = 1;
          break;
        case 'tg':
          social_type = 3;
          break;
        case 'vb':
          social_type = 4;
          break;
      }
      this.gaEvent('Cabinet', 'Click_share_cabinet', 'share_order');
      this._slonik({
        project_id: 1,
        event_id: 352,
        screentype: this.isDesktop ? 1 : 2,
        owner_id: this.userInfo.id,
        proposal_id: this.id,
        version_type: 2,
        current_url: window.location.pathname,
        previous_url: document.referrer,
        lang_id: this.langId,
        click_action: 13,
        social_type
      });
      this.share(type, `${this.langPrefix}${this.info.uri}`);
    }
  }

}
