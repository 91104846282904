import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdvertisementPhoto",
  data() {
	return {
	  loaded: false,
	};
  },
  props: {
    id: Number
  },
  computed: {
    ...mapGetters({
      info_: "cabinet/advertisements/advertisement/info",
      userInfo: "cabinet/userInfo/userData",
      langId: "lang/id"
    }),
    info() {
      return this.info_(this.id);
    },
    img() {
      if (this.info.photos && this.info.photos.main && this.info.photos.main.id) {
        return `https://cdn3.riastatic.com/photosnew/auto/photo/${this.info.brand.eng}_${this.info.model.eng}__${this.info.photos.main.id}bx.jpg`;
      }
      return "";
    },
	webp() {
	  if (this.info.photos && this.info.photos.main && this.info.photos.main.id) {
		return `https://cdn3.riastatic.com/photosnew/auto/photo/${this.info.brand.eng}_${this.info.model.eng}__${this.info.photos.main.id}bx.webp`;
	  }
	  return "";
	},
    countPhotos() {
      if (this.info && this.info.photos && this.info.photos.all && this.info.photos.all.length) {
        return this.info.photos.all.length;
      }
      return 0;
    },
    links() {
      return {
        addForm: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/#PhotoSection`
      }
    }
  },
  methods: {
    addPhoto($event) {
      if (this.info.status === 'DELETED_BY_ADMIN') {
        alert(this.$t('Ваше объявление удалено на модерации, Вы не можете изменять параметры'));
        $event.preventDefault();
      }
      if (!this.img) {
        this._slonik({
          project_id: 1,
          event_id: 352,
          screentype: this.isDesktop ? 1 : 2,
          owner_id: this.userInfo.id,
          proposal_id: this.id,
          version_type: 2,
          current_url: window.location.pathname,
          previous_url: document.referrer,
          lang_id: this.langId,
          click_action: 12,
        });
      }
    }
  },
  i18n: {
    messages: {
      uk: {
        'Ваше объявление удалено на модерации, Вы не можете изменять параметры': 'Ваше оголошення видалено на модерації, Ви не можете змінювати параметри'
      },
      ru: {
        'Ваше объявление удалено на модерации, Вы не можете изменять параметры': 'Ваше объявление удалено на модерации, Вы не можете изменять параметры'
      }
    }
  }
};
