import { mapGetters } from "vuex";
import moment from "moment";

export default {
  props: {
    id: Number
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
    }),
    info() {
      return this.info_(this.id) || {};
    },
    date() {
      if (this.info.deleteReason.date) {
        return moment(this.info.deleteReason.date).format('DD MMM YY').replace('.', '');
      } else {
        return '';
      }
    }
  },
  i18n: {
    messages: {
      uk: {
        Удалено: 'Видалено',
        автоматически: 'автоматично',
        владельцем: 'власником',
        Отмечено: 'Відзначено',
        как: 'як',
        'передумал продавать автомобиль': 'передумав продавати автомобіль',
        'хочу разместить объявление заново': 'хочу розмістити оголошення заново',
        'другом месте': 'іншому місці',
        'с помощью': 'за допомогою',
        подробнее: 'детальніше'
      },
      ru: {
        Удалено: 'Удалено',
        автоматически: 'автоматически',
        владельцем: 'владельцем',
        Отмечено: 'Отмечено',
        как: 'как',
        'передумал продавать автомобиль': 'передумал продавать автомобиль',
        'хочу разместить объявление заново': 'хочу разместить объявление заново',
        'другом месте': 'другом месте',
        'с помощью': 'с помощью',
        подробнее: 'подробнее'
      }
    }
  }
}
