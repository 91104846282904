import { mapActions, mapGetters } from 'vuex';
import { SEGMENT_WITH_SWITCH_PHONE } from '../../../../../enums/segments';

export default {
  data() {
    return {
      showSwitchPhone: false,
      publicationInfo: {},
    };
  },
  props: {
    id: Number,
    ticketClass: String,
    canUploadDocs: Boolean,
    uploadDocsForModeration: Boolean,
  },
  components: {
    Statistic: () => import('../Statistic/index.vue'),
    DeleteReason: () => import('../DeleteReason/index.vue'),
    Position: () => import('../Position/index.vue'),
    DocsUploader: () => import('../DocsUploader/index.vue'),
  },
  methods: {
    ...mapActions({
      openPopup: 'cabinet/advertisements/popups/open',
      fetchOrder: 'shared/advertisement/order',
      getPublicationInfo: 'cabinet/constants/getPublicationInfo',
    }),
    getLevelDiscount() {
      if (this.info.status === 'ACTIVE' && this.publicationInfo) {
        return this.publicationInfo.levelDiscount;
      }
      return 0;
    },
    order() {
      if (this.info.status === 'AWAIT_FOR_PAYMENT') {
        this.fetchOrder({ id: this.id }).then((order) => {
          if (order.url) {
            window.open(order.url, '_blank', 'width=1000,height=650');
          } else {
            window.location = this.links.republish;
          }
        });
      }
    },
    async slonik(action, action_block) {
      this._slonik({
        project_id: 1,
        event_id: action_block ? 452 : 352,
        screentype: this.isDesktop ? 1 : 2,
        owner_id: this.userInfo.id,
        proposal_id: this.id,
        version_type: 2,
        current_url: window.location.pathname,
        previous_url: document.referrer,
        lang_id: this.langId,
        click_action: action,
        action_block,
      });
    },
    publish() {
      // якщо активні
      this.slonik(10);
      // якщо архівні
      // this.slonik(14);
    },
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      userInfo: 'cabinet/userInfo/userData',
      langId: 'lang/id',
      type: 'cabinet/advertisements/type',
      segment: 'cabinet/segment',
      getRepublicationInfoById_: 'cabinet/advertisements/manage/getRepublicationInfoById',
      getProSellerPageData: 'proAccount/getProSellerPageData',
    }),
    deleteReason() {
      return this.info.deleteReason || {};
    },
    allowRestore() {
      const expiredAt = this._result(this.info, 'publication.expiredAt');
      const { status } = this.info;
      return (expiredAt && expiredAt >= Date.now() && status === 'ARCHIVED');
    },
    topLink() {
      let path = `${this.langPrefix}/search/`;
      let query = {
        category_id: this._result(this, 'info.category.id'),
        'marka_id[0]': this._result(this, 'info.brand.id'),
        'model_id[0]': this._result(this, 'info.model.id'),
        scrollToAuto: this.id,
        levelsPopup: this.id,
      };
      // @todo дописати параметри в graphql
      if (this.info.abroad) {
        query = {
          'abroad.not': -1,
          'custom.not': -1,
          ...query,
        };
        path = `${this.langPrefix}/search-order-auto/`;
      }
      return `${path}?${Object.keys(query).map((param) => `${param}=${query[param]}`).join('&')}`;
    },
    links() {
      return {
        republish: `${this.langPrefix}/addauto/republication/autoId/${this.id}/`,
        edit: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/`,
        autotest: `${this.langPrefix}/check_selection/${this.id}/`,
        top: this.topLink,
        publish: `${this.langPrefix}/addauto/publication/autoId/${this.id}/`,
        addVinCode: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/#VIN-block`,
        callsRate: `${this.langPrefix}/cabinet/auction/?popup=${this.id}`,
      };
    },
    info() {
      return this.info_(this.id) || {};
    },
    verification() {
      const { info: { autotest } = {} } = this;
      if (
        this.info.autotest
				&& autotest.technicalPackage.orderId
				&& autotest.lawyerPackage.orderId
				&& autotest.vinPackage.orderId
      ) {
        return 1;
      }
      return 0;
    },
    lawyer() {
      const { info: { autotest } = {} } = {} = this;
      if (autotest) {
        return autotest.lawyerPackage;
      }
      return {};
    },
    technical() {
      const { info: { autotest } = {} } = {} = this;
      if (autotest) {
        return autotest.technicalPackage;
      }
      return {};
    },
    vin() {
      const { info: { autotest } = {} } = this;
      if (autotest) {
        return autotest.vinPackage;
      }
      return {};
    },
    republicationInfo() {
      return this.getRepublicationInfoById_(this.id) || {};
    },
    republicationStatus() {
      return Object.keys(this.republicationInfo).length ? Boolean(Number(this.republicationInfo.active)) : !this._result(this.info, 'aar.disable');
    },
  },
  mounted() {
    this.getPublicationInfo(this.id)
      .then((info) => {
        this.publicationInfo = info;
      });
    if (SEGMENT_WITH_SWITCH_PHONE.includes(this.segment) || this.getProSellerPageData?.active) {
      this.showSwitchPhone = true;
    }
  },
  i18n: require('./i18n').default,
};
