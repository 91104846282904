import {mapGetters} from 'vuex';
export default {
	name: 'AutoBuyStatus',
	props: {
		id: Number,
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			info_: 'cabinet/advertisements/advertisement/info',
		}),
		info() {
			return this.info_(this.id) || {};
		},
		created() {
			return this._result(this.info, 'publication.createdAt');
		},
		status() {
			return Number(this._result(this.info, 'autoBuy.status'));
		},
		link() {
			return this.info.autoBuy ? `${this.langPrefix}/autoBuy/${this.id}` : `/auction/status/${this.id}`;
		},
	},
	methods: {
		formatDate(_date) {
			if (!_date) { return _date; }
			const currentDate = this.m();
			const currentDay = Number(currentDate.format('DD'));
			const date = this.m(_date);
			const day = Number(date.format('DD'));

			let text = '';
			switch (day) {
				case currentDate:
					text = `${this.$t('Сегодня')},`;
					break;
				case currentDay - 1:
					text = `${this.$t('Вчера')},`;
					break;
				case currentDay + 1:
					text = `${this.$t('Завтра')},`;
					break;
				default:
					text = date.format('LL');
					break;
			}
			text += ` ${date.format('HH:mm')}`;
			return text;
		},
	},
	i18n: {
		messages: {
			uk: {
				'Сегодня': 'Сьогодні',
				'Завтра': 'Завтра',
				'Вчера': 'Вчора',
				'Проверить статус заявки': 'Перевірити статус заявки',
				'Заявка на продажу принята': 'Заявка на продаж прийнята',
				детали: 'деталі',
				'Ваша заявка ожидает рассмотрения': 'Ваша заявка очікує на розгляд',
				'Авто готово к продаже за 24 часа': 'Авто готове до продажу за 24 години',
				'У вас запланирована встреча с партнерами AUTO.RIA24': 'У вас запланована зустріч з партнерами AUTO.RIA24',
				'Поздравляем, ваше авто продано за 24 часа': 'Вітаємо, ваше авто продано за 24 години',
				'Спасибо за участие в программе AUTO.RIA24': 'Дякуємо за участь у програмі AUTO.RIA24',
				'Статус вашей заявки': 'Статус вашої заявки',
			},
			ru: {
				'Сегодня': 'Сегодня',
				'Завтра': 'Завтра',
				'Вчера': 'Вчера',
				'Проверить статус заявки': 'Проверить статус заявки',
				'Заявка на продажу принята': 'Заявка на продажу принята',
				детали: 'детали',
				'Ваша заявка ожидает рассмотрения': 'Ваша заявка ожидает рассмотрения',
				'Авто готово к продаже за 24 часа': 'Авто готово к продаже за 24 часа',
				'У вас запланирована встреча с партнерами AUTO.RIA24': 'У вас запланирована встреча с партнерами AUTO.RIA24',
				'Поздравляем, ваше авто продано за 24 часа': 'Поздравляем, ваше авто продано за 24 часа',
				'Спасибо за участие в программе AUTO.RIA24': 'Спасибо за участие в программе AUTO.RIA24',
				'Статус вашей заявки': 'Статус вашей заявки',
			}
		}
	}
};
