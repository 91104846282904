import { mapGetters } from "vuex";

export default {
  props: {
    id: Number
  },
  components: {
    MobileActions: () => import('../MobileActions/index.vue'),
    Share: () => import('../Share/index.vue')
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
    }),
    info() {
      return this.info_(this.id);
    }
  },
  i18n: {
    messages: {
      uk: {
        'Просмотры номеров телефона отображаются за последние 180 дней и обновляются раз в несколько минут': 'Унікальні перегляди номерів телефону покупцями відображаються протягом останніх 180 днів і оновлюються раз на кілька хвилин',
        'Просмотры авто в вашем Личном Кабинете отображаются за последние 180 дней. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице предложения': 'Перегляди авто у вашому Особистому Кабінеті відображаються за останніх 180 днів. Ці дані оновлюються раз на кілька хвилин і можуть відрізнятись від статистики на сторінці пропозиції',
        'Избранное отображает актуальное количество сохраненных предложений. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице авто': 'Обране відображає актуальну кількість збережених пропозицій. Ці дані оновлюються раз на кілька хвилин і можуть відрізнятись від статистики на сторінці авто',
      },
      ru: {
        'Просмотры номеров телефона отображаются за последние 180 дней и обновляются раз в несколько минут': 'Уникальные просмотры номеров телефона покупателями отображаются за последние 180 дней и обновляются раз в несколько минут',
        'Просмотры авто в вашем Личном Кабинете отображаются за последние 180 дней. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице предложения': 'Просмотры авто в вашем Личном Кабинете отображаются за последние 180 дней. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице предложения',
        'Избранное отображает актуальное количество сохраненных предложений. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице авто': 'Избранное отображает актуальное количество сохраненных предложений. Эти данные обновляются раз в несколько минут и могут отличаться от статистики на странице авто',
      }
    }
  }
}
