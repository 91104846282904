import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'DeletePopup',
  components: {
    Popup: () => import('../../../Common/Popup.vue'),
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      popup: 'cabinet/advertisements/popups/popup',
      userInfo: "cabinet/userInfo/userData",
      langId: 'lang/id'
    }),
    info() {
      return this.info_(this.id);
    },
    id () {
      return this.popup.id;
    },
    ids () {
      return this.popup.ids;
    }
  },
  methods: {
    ...mapActions({
      close: 'cabinet/advertisements/popups/close',
      delete: 'cabinet/advertisements/delete',
    }),
    ...mapMutations({
      decrementAdvCntByName: 'cabinet/leftBar/decrementAdvCntByName'
    }),
    delete_() {
      let click_action, proposal_id;
      if (this.id) {
        this.delete({id: this.id})
            .then(() => {
              this.decrementAdvCntByName('MyAdvertisementsArchive');
              this.close();
            })
            .catch((e) => {
              this.close();
              console.log('44, Cabinet/Advertisements/DeletePopup/index.js', e);
            });
        click_action = 18;
        proposal_id = this.id;
      } else {
        Promise
          .all(this.ids.map(id => {
            this.delete({id});
            this.decrementAdvCntByName('MyAdvertisementsArchive');
          }))
          .then(() => {
            alert(this.$t('Объявления удалены'));
            this.close();
          })
          .catch((e) => {
            this.close();
            console.log('60, Cabinet/Advertisements/DeletePopup/index.js', e);
          });
        click_action = 19;
        proposal_id = this.ids;
      }
      this._slonik({
        project_id: 1,
        event_id: 352,
        screentype: this.isDesktop ? 1 : 2,
        owner_id: this.userInfo.id,
        proposal_id,
        version_type: 2,
        current_url: window.location.pathname,
        previous_url: document.referrer,
        lang_id: this.langId,
        click_action
      });
    }
  },
  i18n: {
    messages: {
      uk: {
        'Вы действительно хотите удалить авто?': 'Ви дійсно хочете видалити авто?',
        'После этого оно не будет доступно на AUTO.RIA': 'Після цього воно не буде доступне на AUTO.RIA',
        'Удаление объявления': 'Видалення оголошення',
        'Удалить авто с сайта': 'Видалити авто з сайту',
        'Объявления удалены': 'Оголошення видалені'
      },
      ru: {
        'Вы действительно хотите удалить авто?': 'Вы действительно хотите удалить авто?',
        'После этого оно не будет доступно на AUTO.RIA': 'После этого оно не будет доступно на AUTO.RIA',
        'Удаление объявления': 'Удаление объявления',
        'Удалить авто с сайта': 'Удалить авто с сайта',
        'Объявления удалены': 'Объявления удалены'
      }
    }
  }
};
