export default {
  messages: {
    uk: {
      'Завантажити QR-код': 'Завантажити QR-код',
      'Ваша пропозиція в QR-коді': 'Ваша пропозиція в QR-коді',
      'Роздрукуйте QR-код': 'Роздрукуйте QR-код',
      'Наклейте на авто': 'Наклейте на авто',
      'Запропонуйте просканувати його покупцеві': 'Запропонуйте просканувати його покупцеві',
      'І про ваше авто знатимуть все — достатньо навести камеру смартфона!': 'І про ваше авто знатимуть все — достатньо навести камеру смартфона!',
    },
    ru: {
      'Завантажити QR-код': 'Загрузить QR-код',
      'Ваша пропозиція в QR-коді': 'Ваше предложение в QR-коде',
      'Роздрукуйте QR-код': 'Распечатайте QR-код',
      'Наклейте на авто': 'Наклейте на авто',
      'Запропонуйте просканувати його покупцеві': 'Предложите просканировать его покупателю',
      'І про ваше авто знатимуть все — достатньо навести камеру смартфона!': 'И о вашем авто будут знать все – достаточно навести камеру смартфона!',
    },
  },
};
