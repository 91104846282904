import { mapActions, mapGetters, mapMutations } from 'vuex';
import scrollToTop from '../../../../helpers/cabinetScroll';

export default {
	name: 'MoveToArchivePopup',
	mixins: [require('../../../../mixins/slonDataLogger')],
	components: {
		Popup: () => import('../../../Common/Popup.vue'),
	},
	mounted() {
		this.queryParams = this.$route.query;
		this.slonik(1);
	},
	data() {
		return {
			preloader: false,
			price: undefined,
			currency: 'USD',
			modalBodyName: '',
			modalTitleClass: 'bold',
			title: this.$t('Удаление предложения '),
			queryParams: {},
		};
	},
	computed: {
		...mapGetters({
			info_: 'cabinet/advertisements/advertisement/info',
			popup: 'cabinet/advertisements/popups/popup',
			userInfo: 'cabinet/userInfo/userData',
			type: 'cabinet/advertisements/type',
			langId: 'lang/id',
		}),
		info() {
			return this.info_(this.id);
		},
		id() {
			return this.popup.id;
		},
		ids() {
			return this.popup.ids;
		},
		modalTitle() {
			return (`${this.title} \n ${(this.modalBodyName === '' || this.modalBodyName === 'sold') ? this._result(this.info, 'title') || '' : ''}`).trim();
		},
	},
	methods: {
		...mapActions({
			close: 'cabinet/advertisements/popups/close',
			moveToArchive_: 'cabinet/advertisements/moveToArchive',
			updatePrice_: 'cabinet/advertisements/updatePriceMovedToArchive',
		}),
		...mapMutations({
			incrementArchiveAdvCnt: 'cabinet/leftBar/incrementArchiveAdvCnt',
			decrementAdvCntByName: 'cabinet/leftBar/decrementAdvCntByName'
		}),
		moveToArchive({reason}, nextModal) {
			this.preloader = true;
			if (this.id) {
				this.slonik(reason === 4 ? 11 : (reason === 5 ? 12 : 13), reason);
				this.gaEvent('Cabinet', 'clickon_button_archive', 'Mark_Sold');
				this.moveToArchive_({reason, id: this.id, source: 2})
					.then(() => {
						this.incrementArchiveAdvCnt();
						this.decrementAdvCntByName(this.$route.name);
						this.showNextModalBody(nextModal);
						this.preloader = false;
						this.price = undefined;
					})
					.catch((e) => {
						this.close();
						this.preloader = false;
						console.log('122, Cabinet/Advertisements/MoveToArchivePopup/index.js', e);
					});
			} else {
				Promise
					.all(this.ids.map(id => {
						this.incrementArchiveAdvCnt();
						this.decrementAdvCntByName(this.$route.name);
						this.moveToArchive_({reason, id});
					}))
					.then(() => {
						this.showNextModalBody(nextModal);
						this.preloader = false;
						alert(this.$t('Объявления удалены'));
					})
					.catch((e) => {
						this.close();
						this.preloader = false;
						console.log('139, Cabinet/Advertisements/MoveToArchivePopup/index.js', e);
					});
			}

		},
		showNextModalBody(nextModal) {
			this.modalBodyName = nextModal;
			switch (nextModal) {
				case 'sold':
					this.title = this.$t('Поздравляем с продажей');
					this.slonik(3);
					break;
				case 'thanks':
					this.modalTitleClass = 'bold check-icon';
					this.title = this.$t('Спасибо за вашу открытость! \n Цена сохранена');
					this.slonik(4);
					break;
				case 'addedToArchive':
					this.modalTitleClass = 'bold check-icon';
					this.title = this.$t('Ваше предложение успешно перемещено в архив');
					this.slonik(2);
					break;
			}
		},
		scrollToTop,
		closeAndScrollToTop() {
			this.close();
			this.scrollToTop(0);
		},
		sendPrice() {
			if (this.price) {
				this.updatePrice_({id: this.id, price: this.price, currency: this.currency})
					.catch(err => console.error('ERROR:::updatePrice_', err.message));
				this.showNextModalBody('thanks');
				this.slonik(31, undefined, {price: this.price, currency: this.currency});
			} else {
				this.showNextModalBody('addedToArchive'); // log 0 price here
				this.slonik(31, undefined, {price: 0, currency: this.currency});
			}
		},
		slonik(action, reason, options = {}) {
			this._slonik({
				event_id: 493,
				action_block: 1,
				project_id: 1,
				click_action: action,
				proposal_id: this.id,
				delete_reason: reason,
				user_id: this.userInfo.id,
				owner_id: this.userInfo.id,
				open_price: options.price,
				news_id: this.getCurrencyTypeId(options.currency),
				utm_campaign: this.queryParams.utm_campaign,
				utm_medium: this.queryParams.utm_medium,
				utm_source: this.queryParams.utm_source,
				r_campaign: this.queryParams.r_campaign,
				r_medium: this.queryParams.r_medium,
				r_source: this.queryParams.r_source,
				r_audience: this.queryParams.r_audience,
			});
		},
	},
	i18n: {
		messages: {
			uk: {
				'Удаление объявления': 'Видалення оголошення',
				'Пожалуйста, укажите причину, по которой Вы удаляете объявление': 'Будь ласка, вкажіть причину, по якій Ви видаляєте оголошення',
				'с помощью': 'за допомогою',
				'другом месте': 'іншому місці',
				'разместить объявление': 'розмістити оголошення',
				'Я передумал продавать': 'Я передумав продавати',
				'Я передумал продавать автомобиль': 'Я передумав продавати автомобіль',
				'Как удалить авто с': 'Як видалити авто з',
				'Объявления удалены': 'Оголошення видалені',
				'Поздравляем с продажей': 'Вітаємо з продажем',
				'Пожалуйста, сообщите сумму, за которую было продано авто': 'Будь ласка, повідомте суму, за яку було продано авто',
				'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с AUTO.RIA': 'Ця інформація допоможе в розрахунку ринкових цін на авто і залишиться абсолютно конфіденційною. Дякуємо, що будуєте перевірений інтернет разом із AUTO.RIA',
				'Сумма': 'Сума',
				'Удаление предложения ': 'Видалення пропозиції ',
				'Выберите действие:': 'Виберіть дію:',
				'Не продано, удалить в архив': 'Не продано, видалити в архів',
				'Ваше предложение успешно': 'Ваша пропозиція успішно',
				'перемещено в архив': 'переміщена в архів',
				'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с': 'Ця інформація допоможе в розрахунку ринкових цін і залишиться абсолютно конфіденційною. Дякуємо, що будуєте перевірений інтернет разом з',
				'Сообщить': 'Уточнити',
				'Не хочу уточнять': 'Не хочу уточнювати',
				'Ваше предложение успешно перемещено в архив': 'Ваша пропозиція успішно переміщена в архів',
				'Вы можете восстановить предложение': 'Ви можете відновити пропозицію',
				'Спасибо за вашу открытость! \n Цена сохранена': 'Дякуємо за вашу відкритість! \n Ціна збережена',
				'Закрыть': 'Закрити',
				'из архива': 'з архіву',
				'или добавить новое': 'або додати нову'
			},
			ru: {
				'Удаление объявления': 'Удаление объявления',
				'с помощью': 'с помощью',
				'Я передумал продавать': 'Я передумал продавать',
				'Поздравляем с продажей': 'Поздравляем с продажей',
				'Пожалуйста, сообщите сумму, за которую было продано авто': 'Пожалуйста, сообщите сумму, за которую было продано авто',
				'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с AUTO.RIA': 'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с AUTO.RIA',
				'Удаление предложения ': 'Удаление предложения ',
				'Пожалуйста, укажите причину, по которой Вы удаляете объявление': 'Пожалуйста, укажите причину, по которой Вы удаляете объявление',
				'другом месте': 'другом месте',
				'разместить объявление': 'разместить объявление',
				'Я передумал продавать автомобиль': 'Я передумал продавать автомобиль',
				'Как удалить авто с': 'Как удалить авто с',
				'Объявления удалены': 'Объявления удалены',
				'Сумма': 'Сумма',
				'Выберите действие:': 'Выберите действие:',
				'Не продано, удалить в архив': 'Не продано, удалить в архив',
				'Ваше предложение успешно': 'Ваше предложение успешно',
				'перемещено в архив': 'перемещено в архив',
				'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с': 'Эта информация поможет в расчете рыночных цен на авто и останется абсолютно конфиденциальной. Спасибо, что строите проверенный интернет вместе с',
				'Сообщить': 'Сообщить',
				'Не хочу уточнять': 'Не хочу уточнять',
				'Ваше предложение успешно перемещено в архив': 'Ваше предложение успешно перемещено в архив',
				'Вы можете восстановить предложение': 'Вы можете восстановить предложение',
				'Спасибо за вашу открытость! \n Цена сохранена': 'Спасибо за вашу открытость! \n Цена сохранена',
				'Закрыть': 'Закрыть',
				'из архива': 'из архива',
				'или добавить новое': 'или добавить новое'
			}
		}
	}
};
