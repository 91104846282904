import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

export default {
  props: {
    id: Number
  },
  data() {
    return {
      confirmedOwner: 0,
    };
  },
  components: {
    DeleteReason: () => import('../DeleteReason/index.vue'),
    Position: () => import('../Position/index.vue'),
  },
  computed: {
    ...mapGetters({
      info_: 'cabinet/advertisements/advertisement/info',
      type: 'cabinet/advertisements/type',
      statusConfirmedCar: 'Common/auth/diia/statusConfirmedCar',
    }),
    info() {
      return this.info_(this.id);
    },
    advId() {
      return this.id;
    },
    isAdvActive() {
      let isActive = this.info && this.info.VIN && ['ACTIVE'].includes(this.info.status)
      if (isActive) this.getConfirmedOwner();
      return isActive;
    },
    isConfirmedCar() {
      return this.statusConfirmedCar && this.statusConfirmedCar.includes(Number(this.advId));
    },
    createdAt() {
      return moment(this.info.createdAt).format('DD MMM YY').replace('.', '');
    },
    lawyer() {
      let {info: {autotest} = {}} = {} = this;
      if (autotest) {
        return autotest.lawyerPackage;
      }
      return {};
    },
    technical() {
      let {info: {autotest} = {}} = {} = this;
      if (autotest) {
        return autotest.technicalPackage;
      }
      return {};
    },
    vin() {
      let {info: {autotest} = {}} = this;
      if (autotest) {
        return autotest.vinPackage;
      }
      return {};
    },
    freeCheck() {
      let {info: {autotest} = {}} = this;
      if (autotest && autotest.vinPackage) {
        return autotest.vinPackage.freeCheck;
      }
      return 0;
    },
    topLink() {
      let path = `${this.langPrefix}/search/`;
      let query = {
        'brand.id[0]': this._result(this, 'info.brand.id'),
        'model.id[0]': this._result(this, 'info.model.id'),
        scrollToAuto: this.id,
        levelsPopup: this.id
      };
      // @todo дописати параметри в graphql
      if (this.info.abroad) {
        query = Object.assign({
          'abroad.not': -1,
          'custom.not': -1
        }, query);
        path = `${this.langPrefix}/search-order-auto/`;
      }
      return `${path}?${Object.keys(query).map(param => `${param}=${query[param]}`).join('&')}`;

    },
    links() {
      return {
        republish: `${this.langPrefix}/addauto/republication/autoId/${this.id}/`,
        edit: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/`,
        autotest: `${this.langPrefix}/check_selection/${this.id}/`,
        top: this.topLink,
        publish: `${this.langPrefix}/addauto/publication/autoId/${this.id}/`,
        addVinCode: `${this.langPrefix}/addauto/autoinfo/autoId/${this.id}/#VIN-block`
      };
    }
  },
  methods: {
    ...mapActions({
      updateVisibleDiiaSharePopup: 'Common/auth/diia/updateVisibleDiiaSharePopup',
      fetchConfirmedOwner: 'Common/auth/diia/fetchConfirmedOwner',
    }),
    async getConfirmedOwner() {
      this.confirmedOwner = await this.fetchConfirmedOwner(this.id);
    },
    openDiiaSharePopup() {
      this.updateVisibleDiiaSharePopup({ show: true, advId: this.advId });
      this._slonik({
        event_id: 221,
        current_point_action: 'add_diia',
        previous_point_action: 'my_listigs',
      })
    },
  },
  i18n: {
    messages: {
      uk: {
        Поднято: 'Піднято',
        Публикация: 'Публікація',
        до: 'до',
        день: '0 днів | {count} день | {count} дні | {count} днів',
        часов: '0 годин | {count} година | {count} години | {count} годин',
        минут: '0 хвилин | {count} хвилина | {count} хвилини | {count} хвилин',
        еще: 'ще',
        Создано: 'Створене',
        тыс: 'тис',
        Договорная: 'Договірна',
        Поднять: 'Підняти',
        Переопубликовать: 'Переопублікувати',
        'Поднять в поиске': 'Підняти в пошуку',
        'Обновление даты и продление срока публикации объявления': 'Оновлення дати та продовження терміну публікації оголошення',
        'Підтвердіть, що ви власник авто': 'Підтвердіть, що ви власник авто',
      },
      ru: {
        Поднято: 'Поднято',
        Публикация: 'Публикация',
        день: '0 дней | {count} день | {count} дня | {count} дней',
        часов: '0 часов | {count} час | {count} часа | {count} часов',
        минут: '0 минут | {count} минута | {count} минуты | {count} минут',
        до: 'до',
        еще: 'еще',
        Создано: 'Создано',
        тыс: 'тыс',
        Договорная: 'Договорная',
        Поднять: 'Поднять',
        Переопубликовать: 'Переопубликовать',
        'Поднять в поиске': 'Поднять в поиске',
        'Обновление даты и продление срока публикации объявления': 'Обновление даты и продление срока публикации объявления',
        'Підтвердіть, що ви власник авто': 'Подтвердите, что вы владелец авто',
      }
    }
  }
};
